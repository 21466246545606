import * as React from "react"
import { useTranslation } from "react-i18next"
import { TITLE, COUNTRY_ID, COLOR_PRIMARY } from 'gatsby-env-variables'

export default function Example() {

    const { t } = useTranslation()

    return (
        <div>
            <hr />
            <main className="lg:relative">
                <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
                    <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
                        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                            <span className="block xl:inline">{ TITLE }</span>{' '}
                            <span className={`block text-primary-600 xl:inline`}>{ t('home.title') }</span>
                        </h1>
                        <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
                            { t('home.description',{'country_name': t('countries.names.' + COUNTRY_ID)}) }
                        </p>
                        <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                            <div className="rounded-md shadow">
                                <a
                                    href="/languages"
                                    className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 md:py-4 md:text-lg md:px-10`}
                                >
                                    { t('languages.title') }
                                </a>
                            </div>
                            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                                <a
                                    href="https://inscript.org"
                                    className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-primary-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10`}
                                >
                                    {t('bibles.types.study')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
                    <img
                        className="absolute inset-0 w-full h-full object-cover"
                        src={`img/countries/${COUNTRY_ID}.jpg`}
                        alt=""
                    />
                </div>
            </main>

        </div>
    )
}

